<div
  class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-51 _x_flex _x_h-full _x_max-h-full _x_w-full _x_items-center _x_justify-center _x_overflow-x-hidden _x_overflow-y-hidden _x_p-4">
  <div
    class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_h-full _x_w-full _x_bg-dark-600"
    (click)="closeAddressFormModal()"></div>
  <div
    #addressFormElement
    [ngClass]="{
      '_x_address-form-frame _x_h-full _x_animate-address-form _x_overflow-hidden': openMapMobile,
      '_x_address-form-opened-frame _x_animate-address-form-opened': !openMapMobile && openMapMobileIsTriggered,
      '_x_wiggles _x_animate-wiggle': !openMapMobile && !openMapMobileIsTriggered
    }"
    class="_x_absolute _x_bottom-0 _x_z-10 _x_h-auto _x_max-h-full _x_w-full _x_max-w-325 _x_translate-y-0 _x_transform _x_overflow-hidden sm:_x_bottom-auto sm:_x_animate-none">
    <div
      [ngClass]="{ '_x_rounded-none': openMapMobile }"
      class="_x_relative _x_h-full _x_rounded-12 _x_rounded-b-none _x_bg-white sm:_x_w-325 sm:_x_rounded-b-12">
      <div class="_x_flex _x_items-center _x_justify-between _x_border-b _x_border-gray _x_p-8">
        <div class="_x_flex _x_items-center">
          <i
            *ngIf="openMapMobile"
            class="_x_icon _x_icon-arrow-left _x_mr-4 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-black"
            (click)="closeMobileOpenMap()">
          </i>

          <p class="_x_font-bold _x_text-5">მიტანის მისამართი</p>
        </div>
        <button type="button" (click)="closeAddressFormModal()">
          <i
            class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-black"></i>
        </button>
      </div>

      <div
        class="ng-star-inserted category-custom-scroll-bar custom-scroll-bar _x_max-h-[-webkit-fill-available] _x_overflow-y-scroll _x_p-8"
        style="height: (100% - 60px)"
        *ngIf="!openMapMobile">
        <div
          *ngIf="isFromExpress"
          class="_x_mb-6 _x_flex _x_items-center _x_rounded-8 _x_bg-purple-100 _x_p-6 md:_x_p-8">
          <i
            class="_x_icon-info _x_mr-4 _x_flex _x_h-11 _x_min-h-11 _x_w-11 _x_min-w-11 _x_items-center _x_text-8 _x_text-black group-hover:_x_text-black"></i>
          <p class="_x_font-medium _x_text-3">პროდუქტების ხელმისაწვდომობა განისაზღვრება მისამართის მიხედვით.</p>
        </div>
        <div [formGroup]="addressForm" class="_x_flex _x_h-full _x_flex-col _x_items-stretch">
          <div class="_x_mb-6 _x_w-full">
            <div class="form-group _x_custom-new-drop _x_relative _x_mb-0">
              <app-dynamic-select
                data-cy="choose_city"
                [addOptionButton]="false"
                [filterBy]="'sortIndex'"
                class="w-100 form-control placeholder-transparent font-bold text-gray ng--custom-selectbox px-0 _x_custom-new-formitem"
                placeholder="აირჩიე ქალაქი"
                [dataGetter]="getLocationTODO"
                [itemGetterById]="getLocationById"
                sortField="description"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="false"
                [customSearch]="true"
                (change)="setLocationBasedOnOurCities($event)"
                (open)="isDropdownOpen = true"
                (closed)="isDropdownOpen = false"
                [value]="addressForm?.get('locationId')?.value">
              </app-dynamic-select>
              <div
                class="error-tooltip tooltip tooltip-bottom-right position-absolute _x_bg-red _x_font-medium _x_text-2 _x_text-white"
                *ngIf="addressForm.get('locationId').touched && addressForm.get('locationId').invalid">
                <div *ngIf="addressForm.get('locationId').hasError('required')">
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div
            [ngClass]="isLocationIdSet ? '_x_pointer-events-auto' : '_x_pointer-events-none _x_opacity-40'"
            class="_x_mb-6">
            <div class="_x_mb-6 _x_w-full">
              <div class="_x_relative">
                <input
                  data-cy="add_address"
                  *ngIf="isMobile"
                  type="text"
                  [value]="addressForm.get('address').value"
                  (focus)="openMapForMobile()"
                  class="_x_scrollbar-thumb-rounded-8 _x_max-w-full _x_h-24 _x_min-h-24 _x_w-full _x_resize-none _x_rounded-8 _x_border _x_border-dark-200 _x_pb-0 _x_pl-10 _x_pr-10 _x_pt-8 _x_font-medium _x_text-3 _x_text-dark-700 _x_outline-none _x_transition _x_duration-150 _x_scrollbar-thin _x_scrollbar-thumb-dark-200 _x_scrollbar-track-transparent focus:_x_border-black md:_x_min-h-24" />
                <input
                  data-cy="add_address"
                  [hidden]="isMobile"
                  type="text"
                  placeholder=""
                  #autocompleInput
                  formControlName="address"
                  (focus)="onAddressInputFocus()"
                  (blur)="onAddressInputFocusOut()"
                  (input)="setAddressInputIvalid()"
                  class="_x_scrollbar-thumb-rounded-8 _x_max-w-full _x_h-24 _x_min-h-24 _x_w-full _x_resize-none _x_rounded-8 _x_border _x_border-dark-200 _x_pb-0 _x_pl-10 _x_pr-10 _x_pt-8 _x_font-medium _x_text-3 _x_text-dark-700 _x_outline-none _x_transition _x_duration-150 _x_scrollbar-thin _x_scrollbar-thumb-dark-200 _x_scrollbar-track-transparent focus:_x_border-black md:_x_min-h-24" />
                <label
                  [ngClass]="[
                    addressForm.get('address').value?.length || isAddressInputFocused
                      ? '_x_text-1'
                      : '_x_top-8 _x_text-3'
                  ]"
                  class="_x_pointer-events-none _x_absolute _x_left-10 _x_top-3 _x_flex _x_h-7 _x_items-center _x_font-medium _x_text-dark-700 _x_duration-300 _x_ease-in-out group-focus:_x_text-red">
                  მისამართი
                </label>
              </div>
            </div>
            <textarea
              data-cy="address_additional_info"
              formControlName="addressAdditionalInfo"
              rows="4"
              cols="50"
              placeholder="სადარბაზო, სართული, ბინა, ა.შ"
              maxlength="200"
              class="_x_scrollbar-thumb-rounded-8 _x_min-h-35 md:_x_min-h-35 _x_max-w-full _x_block _x_h-35 _x_w-full _x_resize-none _x_rounded-8 _x_border _x_border-dark-200 _x_p-7 _x_font-medium _x_text-3 _x_text-dark-700 _x_outline-none _x_transition _x_duration-150 _x_scrollbar-thin _x_scrollbar-thumb-dark-200 _x_scrollbar-track-transparent focus:_x_border-black md:_x_pt-9"></textarea>

            <p class="_x_mt-8 _x_hidden _x_font-semibold _x_text-3 _x_text-dark sm:_x_mt-6 sm:_x_flex">
              აირჩიე მისამართი მონიშვნით
            </p>
          </div>
          <div *ngIf="!isMobile || isLatLongSet" class="_x_mb-8 _x_flex _x_h-55 _x_w-full _x_rounded-12 sm:_x_h-90">
            <google-map
              class="_x_w-full _x_overflow-hidden _x_rounded-12"
              height="100%"
              width="100%"
              [zoom]="12"
              [center]="mapCenter"
              [options]="mapOptions"
              [style.filter]="isLocationIdSet ? '' : 'grayscale(100%)'"
              (mapClick)="onMapClick($event)">
              <map-marker
                *ngIf="selectedPin && isLocationIdSet"
                [options]="markerOptions"
                [position]="selectedPin"></map-marker>
            </google-map>
          </div>
          <div
            *ngIf="!isLatLongSet"
            [ngClass]="{ '_x_pointer-events-none _x_opacity-40': !isLocationIdSet }"
            class="sm:_x_hidden">
            <p class="_x_mb-6 _x_font-bold _x_text-3 _x_text-dark">გახსენი რუკა და აირჩიე მისამართი</p>
            <button
              class="_x_mb-10 _x_flex _x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_border-1 _x_border-purple _x_font-bold _x_text-3 _x_text-purple _x_transition _x_duration-300 hover:_x_border-2 sm:_x_mb-8"
              (click)="openMapForMobile()">
              აირჩიე მისამართი რუკაზე
            </button>
          </div>
          <div
            class="_x_mb-16 _x_flex _x_items-center _x_justify-start sm:_x_mb-8"
            [ngClass]="{ '_x_pointer-events-none _x_opacity-40': !isLocationIdSet || disabledDefaultAddressCheckbox }">
            <label
              data-cy="default_address_checkbox"
              class="_x_flex _x_cursor-pointer _x_flex-row-reverse _x_items-center _x_font-medium _x_text-3 _x_text-black"
              for="isDefaultCheckbox">
              ძირითად მისამართად მონიშვნა
              <div
                [ngClass]="{ '_x_bg-purple': addressForm.get('isDefault').value }"
                class="_x_mr-4 _x_flex _x_h-12 _x_w-12 _x_items-center _x_justify-center _x_rounded-6 _x_border _x_border-dark-300">
                <i
                  class="_x_icon _x_icon-done-check _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-white"></i>
              </div>
            </label>

            <!-- Hidden Checkbox Input -->
            <input type="checkbox" id="isDefaultCheckbox" formControlName="isDefault" hidden />
          </div>
          <button
            data-cy="submit_address"
            class="_x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_font-medium _x_text-white _x_transition _x_duration-300 lg:_x_flex"
            (click)="addressForm.valid && submitAddressData()"
            [disabled]="
              addressForm.invalid ||
              addressInputIsInvalid ||
              (!addressForm.touched && !addressForm.dirty) ||
              disableSubmit
            "
            [ngClass]="
              addressForm.valid && !addressInputIsInvalid && (addressForm.touched || addressForm.dirty)
                ? '_x_bg-purple hover:_x_bg-purple-100 hover:_x_text-purple'
                : '_x_cursor-not-allowed _x_bg-dark-300'
            ">
            <span class="_x_font-bold _x_text-3">
              <span class="_x_hidden sm:_x_inline"> მისამართის </span>
              {{ isEditMode ? 'შენახვა' : 'დამატება' }}
            </span>
          </button>
        </div>
      </div>
      <div *ngIf="isMobile && openMapMobile" class="_x_h-calc100full-60 _x_p-8">
        <div class="_x_flex _x_h-full _x_flex-col _x_items-stretch">
          <div class="_x_relative _x_h-full">
            <div class="_x_relative">
              <input
                data-cy="add_address"
                *ngIf="isMobile"
                type="text"
                placeholder=""
                #autocompleteMobile
                (focus)="onAddressInputFocus()"
                (blur)="onAddressInputFocusOut()"
                (input)="setAddressInputIvalid()"
                class="_x_scrollbar-thumb-rounded-8 _x_max-w-full _x_mb-8 _x_h-24 _x_min-h-24 _x_w-full _x_resize-none _x_rounded-8 _x_border _x_border-dark-200 _x_pb-0 _x_pl-10 _x_pr-10 _x_pt-8 _x_font-medium _x_text-3 _x_text-dark-700 _x_outline-none _x_transition _x_duration-150 _x_scrollbar-thin _x_scrollbar-thumb-dark-200 _x_scrollbar-track-transparent focus:_x_border-black md:_x_min-h-24" />
              <label
                [ngClass]="[
                  autocompleteMobile?.nativeElement?.value?.length || isAddressInputFocused
                    ? '_x_text-1'
                    : '_x_top-8 _x_text-3'
                ]"
                class="_x_pointer-events-none _x_absolute _x_left-10 _x_top-3 _x_flex _x_h-7 _x_items-center _x_font-medium _x_text-dark-700 _x_duration-300 _x_ease-in-out group-focus:_x_text-red">
                მისამართი
              </label>
            </div>

            <div class="_x_h-calc100full-130 _x_w-full">
              <google-map
                class="_x_overflow-hidden _x_rounded-12"
                height="100%"
                width="100%"
                [zoom]="12"
                [center]="mapCenterMobile"
                [options]="mapOptions"
                [style.filter]="isLocationIdSet ? '' : 'grayscale(100%)'"
                (mapClick)="onMobileMapClick($event)">
                <map-marker
                  *ngIf="selectedPinMobile && isLocationIdSet"
                  [options]="markerOptions"
                  [position]="selectedPinMobile"></map-marker>
              </google-map>
            </div>
            <button
              class="_x_my-8 _x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_font-medium _x_text-white lg:_x_flex"
              [disabled]="addressInputIsInvalid || !autocompleteMobile?.nativeElement?.value"
              [ngClass]="
                !addressInputIsInvalid && autocompleteMobile?.nativeElement?.value
                  ? '_x_bg-purple hover:_x_bg-purple-100 hover:_x_text-purple'
                  : '_x_cursor-not-allowed _x_bg-dark-300'
              "
              (click)="changeAddressChangesForMobile()">
              <span class="_x_font-bold _x_text-3">შენახვა</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
