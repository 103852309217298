<div
  class="_x_sticky _x_top-8 _x_z-51 _x_ml-auto _x_mr-auto _x_h-0 _x_w-full _x_max-w-780 _x_px-8"
  *ngIf="(currentToastsArray$ | async).length !== 0"
  [ngClass]="[(selectRibbonActiveState$ | async) ? 'md:_x_top-65' : 'md:_x_top-41']">
  <div class="_x_sticky _x_ml-auto _x_mr-auto _x_max-w-max md:_x_mr-0 md:_x_pt-24">
    <div
      class="_x_mx-auto _x_max-w-max md:_x_ml-auto md:_x_mr-unset"
      *ngFor="let toast of currentToastsArray$ | async; index as i">
      <app-toast
        class="fade-animation"
        [isComparison]="toast.isComparison"
        [type]="toast.type"
        [title]="toast.title"
        [message]="toast.message"
        [id]="i"
        (closeEvent)="closeToast($event)"></app-toast>
    </div>
  </div>
</div>
