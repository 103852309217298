<div id="global_modal_area">
  <div *ngIf="globalModalService.component$ | async as modalComponent" class="popup-wrapper">
    <div
      (click)="globalModalService.closeOnOutsideClick && close()"
      [class]="modalWrapperClass"
      class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-51 _x_h-full _x_w-full _x_bg-dark-600"></div>
    <ng-container *ngIf="!(globalModalService.inputs$ | async)">
      <ng-container *ngComponentOutlet="modalComponent"></ng-container>
    </ng-container>
  </div>
</div>
