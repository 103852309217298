import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../../services/error.service';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorService: ErrorService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('IsImpersonatedUser') && isPlatformBrowser(this.platformId)) {
      const authToken = localStorage.getItem('access_token');
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // EX-9678 თასქის ჭრილში გახდა საჭირო isNotCheckoutPage-ის ჩეკი
        const isNotCheckoutPage = !this.router.url.includes('checkout');
        // should be constant!
        if (error.status === 503) {
          this.errorService.addErrors(Array.isArray(error.message) ? error.message : [error.message]);
        } else if (error.status === 403 && isNotCheckoutPage) {
          this.router.navigate(['/']);
        }
        return throwError(() => error);
      })
    );
  }
}
