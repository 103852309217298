import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { CatalogHttp } from 'lib-core';
import { deleteComparison, updateComparison } from '../store/actions/comparison.actions';
import { getUserStatus } from '../store/reducers/auth.reducer';
import { getComparisonProducts } from '../store/reducers/comparison.reducer';
import { ToastService } from './toast.service';

export interface ProductType {
  productId: number;
  productType: string;
}

@Injectable({
  providedIn: 'root'
})
export class ComparisonService implements OnDestroy {
  userStatus$ = this.store.pipe(select(getUserStatus));
  isLoggedIn = false;
  private delete$ = new BehaviorSubject<boolean>(false);
  deletedProduct$ = this.delete$.asObservable();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private toastService: ToastService,
    private store: Store,
    private catalogService: CatalogHttp
  ) {
    this.userStatus$.subscribe(status => {
      this.isLoggedIn = status;
    });
  }
  listenComparisonDelete() {
    this.delete$.next(true);
  }
  getCorrectProductsData(productType?: ProductType | null) {
    //update and get
    let productIds = [];
    let comparisonArray;
    if (isPlatformBrowser(this.platformId)) {
      const itemFromLocalStorage = localStorage.getItem('comparison');
      comparisonArray = itemFromLocalStorage ? JSON.parse(itemFromLocalStorage) : [];
    }
    if (!!comparisonArray?.length) {
      productIds.push(...comparisonArray.map(i => i.productId));
    }
    if (productType) {
      //for add product in comparison array
      productIds.push(productType.productId);
      comparisonArray.push(productType);
    }

    return {
      productIds,
      comparisonArray
    };
  }

  transformAndSaveComparisonInStorage(comparisonArray?: any[], productsIds?: number[]): void {
    let transformedArray;
    if (productsIds) {
      transformedArray = comparisonArray?.filter(item => productsIds.some(id => id === item.productId));
    } else {
      transformedArray = [...comparisonArray];
    }
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('comparison', JSON.stringify(transformedArray));
    }
  }
  removeComparisonProductFromLocalStorage(productId) {
    if (isPlatformBrowser(this.platformId)) {
      const comparisonArray = JSON.parse(localStorage.getItem('comparison') || '[]');
      const result = comparisonArray.filter(obj => obj.productId !== productId);
      localStorage.setItem('comparison', JSON.stringify(result));
    }
  }

  clearComparisonLocalStorage() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('comparison', JSON.stringify([]));
    }
  }
  getUserComparisonProducts(): Observable<number[]> {
    return this.store.pipe(
      take(1),
      select(getComparisonProducts),
      switchMap(products => {
        return this.catalogService.clearComparison().pipe(
          map(() => {
            return { products: products };
          })
        );
      }),
      switchMap(({ products }) => {
        return this.catalogService.addUserComparisonProductsMultiple({ productIds: products });
      })
    );
  }

  checkSameCategory(categoryId: number): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const itemFromLocalStorage = localStorage.getItem('comparison');
      const products = JSON.parse(itemFromLocalStorage || '[]');
      return !!products.find(element => element.categoryId !== categoryId);
    }
  }
  checkSameIdExsitance(productId: number, productIdsArray: number[]): boolean {
    let result = productIdsArray.find(id => id === productId);
    return result === productId;
  }

  updateComparisonHandle(product): void {
    const MIN_PRODUCTS = 4;
    if (this.isLoggedIn) {
      this.store.dispatch(updateComparison({ productId: product.id, categoryId: product.categoryId }));
    } else {
      this.store.pipe(take(1), select(getComparisonProducts)).subscribe(res => {
        if (this.checkSameIdExsitance(product.id, res)) {
          this.store.dispatch(deleteComparison({ productId: product.id }));
        } else if (this.checkSameCategory(product.categoryId)) {
          this.toastService.showToastEvent(null, 'შეარჩიე იდენტური კატეგორიის პროდუქტები', 'error', true);
        } else if (res?.length >= MIN_PRODUCTS) {
          this.toastService.showToastEvent(null, 'მაქსიმუმ 4 პროდუქტს შეადარებ', 'error', true);
        } else {
          this.store.dispatch(updateComparison({ productId: product.id, categoryId: product.categoryId }));
        }
      });
    }
  }

  handleErrorCodes(errorCode, product) {
    if (errorCode === '411') {
      this.toastService.showToastEvent(null, 'შეარჩიე იდენტური კატეგორიის პროდუქტები', 'error', true);
    } else if (errorCode === '410') {
      // if its identical , remove from compoarison
      this.store.dispatch(deleteComparison({ productId: product.productId }));
    } else if (errorCode === '412') {
      this.toastService.showToastEvent(null, 'მაქსიმუმ 4 პროდუქტს შეადარებ', 'error', true);
    }
  }

  ngOnDestroy() {
    this.delete$.complete();
  }
}
