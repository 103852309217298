import { OnInit, ChangeDetectionStrategy, Component, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UserFeedbackQuestionsComponent } from './user-feedback-questions/user-feedback-questions.component';
import { CommonModule, NgClass } from '@angular/common';
import { UserFeedbackOrderDetailsComponent } from './user-feedback-order-details/user-feedback-order-details.component';
import { GlobalModalService } from '../../services/global-modal-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { feedbackOrderModalInputs } from '../../shared/components/global-modal-wrapper/utility/global-modals.model';
import { feedbackView, ReviewQuestionsInterface, UserFeedbackService } from './user-feedback.service';
import { OrderingHttp } from 'lib-core';
import { Subject, takeUntil } from 'rxjs';
import { IsMobileService } from '../../services/is-mobile.service';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    UserFeedbackQuestionsComponent,
    UserFeedbackOrderDetailsComponent,
    NgClass
  ],
  standalone: true
})
export class UserFeedbackComponent implements feedbackOrderModalInputs, OnInit, OnDestroy {
  @Input() orderId: string = '';
  @Input() orderExternalId: string = '';
  @Input() fromHome: boolean = false;
  public currentQuestion: ReviewQuestionsInterface;
  public totalQuestionsCount: number;
  public inProgressPercent: string;
  public currentQuestionIndex: number = 0;
  public answeredCount: number = 0;
  public feedbackView = feedbackView;
  public isUpTo1023px: boolean;
  private reviewQuestions: ReviewQuestionsInterface[];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private globalModalService: GlobalModalService,
    public userFeedbackService: UserFeedbackService,
    private cdr: ChangeDetectorRef,
    private orderingHttp: OrderingHttp,
    private isMobileService: IsMobileService
  ) {}

  ngOnInit(): void {
    if (this.orderId || this.fromHome) {
      if (this.orderId) {
        this.userFeedbackService.setOrderId(this.orderId, this.orderExternalId);
      }
      this.userFeedbackService
        .getReviewQuestions$()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res: { answered: number; reviewQuestions: ReviewQuestionsInterface[] }) => {
            if (res) {
              this.setInitialState(res);
            } else if (res === null) {
              this.globalModalService.close();
            }
          }
        });
    }
    this.subscribeToScreenResolution();
  }

  public submitAnswer(data) {
    this.orderingHttp.reviewQuestionAnswer(data).subscribe({
      next: response => {
        this.moveToNextQuestion();
      },
      error: error => {
        this.userFeedbackService.setFeedbackView(feedbackView.error);
        this.cdr.markForCheck();
      }
    });
  }

  public reaskQuestion(): void {
    this.userFeedbackService.setFeedbackView(feedbackView.feedback);
    this.cdr.markForCheck();
  }

  public closeModal(callCallbackMethod = false): void {
    callCallbackMethod && this.globalModalService.params?.callBackFn && this.globalModalService.params.callBackFn();
    this.globalModalService.close();
  }

  public rejectReviewQuestions(): void {
    this.orderingHttp
      .rejectReviewQuestions(this.currentQuestion?.orderExternalId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.globalModalService.params?.callBackFn && this.globalModalService.params.callBackFn();
          this.globalModalService.close();
        },
        error: () => {
          this.globalModalService.close();
        }
      });
  }

  private loadCurrentQuestion(): void {
    this.currentQuestion = this.reviewQuestions[this.currentQuestionIndex];
    this.cdr.markForCheck();
  }

  private moveToNextQuestion(): void {
    if (this.currentQuestionIndex < this.reviewQuestions.length - 1) {
      this.currentQuestionIndex++;
      this.inProgressPercent =
        ((this.answeredCount + this.currentQuestionIndex + 1) / this.totalQuestionsCount) * 100 + '%';
      this.loadCurrentQuestion();
    } else {
      this.currentQuestion = null;
      this.userFeedbackService.setFeedbackView(feedbackView.success);
      this.cdr.markForCheck();
    }
  }
  private subscribeToScreenResolution() {
    this.isMobileService.isUpTo1023px.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      this.isUpTo1023px = res;
      this.cdr.markForCheck();
    });
  }
  private setInitialState(res: { answered: number; reviewQuestions: ReviewQuestionsInterface[] }) {
    this.reviewQuestions = res.reviewQuestions;
    this.currentQuestionIndex = 0;
    this.totalQuestionsCount = res.reviewQuestions?.length + res.answered;
    this.answeredCount = res.answered;
    this.inProgressPercent = ((this.answeredCount + 1) / this.totalQuestionsCount) * 100 + '%';
    this.loadCurrentQuestion();
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
