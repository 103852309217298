<div
  class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-51 _x_flex _x_h-full _x_max-h-full _x_w-full _x_items-center _x_justify-center _x_overflow-x-hidden _x_overflow-y-hidden _x_p-4">
  <div
    class="_x_fixed _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_h-full _x_w-full _x_bg-dark-600"
    (click)="closeAddressListingModal()"></div>
  <div
    class="_x_wiggle _x_wiggles _x_absolute _x_bottom-0 _x_z-10 _x_h-auto _x_max-h-full _x_w-full _x_max-w-262 _x_translate-y-0 _x_transform _x_animate-wiggle _x_overflow-hidden _x_rounded-t-12 _x_bg-white sm:_x_bottom-auto sm:_x_animate-none sm:_x_rounded-b-12">
    <div class="_x_relative _x_rounded-t-12 _x_bg-white sm:_x_w-full">
      <div class="_x_flex _x_items-center _x_justify-between _x_border-b _x_border-gray _x_p-8">
        <div class="_x_flex _x_items-center">
          <p class="_x_font-bold _x_text-5" *ngIf="isSelectedOptionTypeCourier">
            {{ addresses.length ? 'მისამართის შეცვლა' : 'მიტანის მისამართი' }}
          </p>
          <p class="_x_font-bold _x_text-5" *ngIf="!isSelectedOptionTypeCourier">მისამართის არჩევა</p>
        </div>
        <button
          class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-dark-700"
          (click)="closeAddressListingModal()"></button>
      </div>
      <div class="_x_p-8">
        <div class="_x_flex _x_flex-col _x_items-center _x_items-stretch">
          <div class="_x_relative">
            <div
              *ngIf="isFromExpress"
              class="_x_mb-6 _x_flex _x_items-center _x_rounded-8 _x_bg-purple-100 _x_p-6 md:_x_p-8">
              <i
                class="_x_icon-info _x_mr-4 _x_flex _x_h-11 _x_min-h-11 _x_w-11 _x_min-w-11 _x_items-center _x_text-8 _x_text-black group-hover:_x_text-black"></i>
              <p class="_x_font-medium _x_text-3">პროდუქტების ხელმისაწვდომობა განისაზღვრება მისამართის მიხედვით.</p>
            </div>
            <div
              class="category-custom-scroll-bar custom-scroll-bar _x_mb-8 _x_flex _x_max-h-178 _x_flex-col _x_items-start _x_overflow-y-auto _x_pr-2 sm:_x_mb-12 sm:_x_max-h-188 sm:_x_pr-4">
              <div
                data-cy="add_new_address"
                *ngIf="isSelectedOptionTypeCourier"
                class="_x_mb-8 _x_flex _x_flex-1-0 _x_cursor-pointer _x_items-center"
                (click)="addNewAddress()">
                <i
                  class="_x_icon _x_icon-plus _x_mr-4 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-link-blue"></i>
                <p class="_x_font-semibold _x_text-3 _x_text-link-blue">მისამართის დამატება</p>
              </div>
              <ng-container *ngIf="addresses">
                <div
                  *ngFor="let address of addresses"
                  [ngClass]="{
                    '_x_border-purple': localSelectedAddressId === address.id,
                    '_x_border-dark-200': localSelectedAddressId !== address.id,
                    '_x_py-5 sm:_x_py-11': isSelectedOptionTypeCourier,
                    '_x_py-8': !isSelectedOptionTypeCourier
                  }"
                  class="_x_col-span-12 _x_mb-6 _x_flex _x_w-full _x_flex-1-0 _x_flex-col _x_items-start _x_justify-between _x_rounded-8 _x_border _x_px-8 last:_x_mb-0 md:_x_flex-row md:_x_items-center">
                  <div
                    class="_x_flex _x_min-h-42 _x_w-full _x_flex-row _x_items-center _x_justify-between sm:_x_min-h-auto sm:_x_items-start sm:_x_items-center">
                    <div
                      class="_x_flex _x_cursor-pointer _x_items-center _x_overflow-hidden"
                      (click)="setActiveAddressIdLocally(address)">
                      <div
                        class="_x_mr-8 _x_flex _x_h-9 _x_w-9 _x_min-w-9 _x_items-center _x_justify-center _x_rounded-full _x_border-2 _x_text-dark-400"
                        [ngClass]="{ '_x_border-purple': localSelectedAddressId === address.id }">
                        <i
                          class="_x_h-4 _x_w-4 _x_rounded-8"
                          [ngClass]="{ '_x_bg-purple': localSelectedAddressId === address.id }"></i>
                      </div>

                      <div *ngIf="isSelectedOptionTypeCourier">
                        <p class="_x_font-medium _x_text-3 _x_text-dark">{{ address.address }}</p>
                        <p *ngIf="address.address" class="_x_mt-4 _x_font-medium _x_text-2 _x_text-dark-700">
                          {{ address.addressAdditionalInfo }}
                        </p>
                      </div>
                      <div
                        *ngIf="!isSelectedOptionTypeCourier"
                        class="_x_flex _x_flex-col _x_items-start _x_overflow-hidden">
                        <p class="_x_font-medium _x_text-3 _x_text-dark">{{ address.address }}</p>
                        <p class="_x_mb-4 _x_mt-4 _x_font-medium _x_text-2 _x_text-dark-700">
                          {{ address.workingHours }}
                        </p>
                        <p
                          class="_x_cursor-pointer _x_font-bold _x_text-3 _x_text-link-blue"
                          (click)="openGoogleMaps(address)">
                          რუკაზე ნახვა
                          <span>
                            <i
                              class="_x_icon-arrow-right _x_relative _x_top-2 _x_h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_rotate-45 _x_transform _x_border-0 _x_text-5">
                            </i>
                          </span>
                        </p>
                      </div>
                    </div>

                    <div *ngIf="isSelectedOptionTypeCourier" class="_x_flex _x_flex-col _x_items-center sm:_x_flex-row">
                      <i
                        class="_x_icon _x_icon-edit _x_mb-10 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-dark-400 sm:_x_mb-0 sm:_x_mr-8"
                        (click)="editAddress(address)"></i>
                      <i
                        class="_x_icon _x_icon-remove-bin _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-7 _x_text-dark-400"
                        (click)="openAdressDeletionConfirmationModal(address?.id)"></i>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="_x_mt-8 _x_flex _x_items-center _x_gap-4 sm:_x_mt-4">
              <ng-container *ngIf="isSelectedOptionTypeCourier; else pickUpAgreement">
                <button
                  data-cy="close_address_listing_modal"
                  class="_x_flex _x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_border-1 _x_border-purple _x_font-bold _x_text-3 _x_text-purple _x_transition _x_duration-300 hover:_x_border-2"
                  (click)="closeAddressListingModal()">
                  გაუქმება
                </button>
                <button
                  data-cy="save_listing_changes"
                  class="_x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_border-1 _x_border-purple _x_bg-purple _x_font-medium _x_text-3 _x_text-white _x_transition _x_duration-300 hover:_x_border-purple-100 hover:_x_bg-purple-100 hover:_x_text-purple lg:_x_flex"
                  (click)="saveListingChanges()">
                  შენახვა
                </button>
              </ng-container>
              <ng-template #pickUpAgreement>
                <button
                  data-cy="save_listing_changes_pickup"
                  class="_x_h-24 _x_w-full _x_items-center _x_justify-center _x_rounded-8 _x_border-1 _x_border-purple _x_bg-purple _x_font-medium _x_text-3 _x_text-white _x_transition _x_duration-300 hover:_x_border-purple-100 hover:_x_bg-purple-100 hover:_x_text-purple lg:_x_flex"
                  (click)="saveListingChanges()">
                  არჩევა
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
