import { createAction, props } from '@ngrx/store';
import { GetProductModel, GetProductsModel, ProductModel } from '../models/product.model';
import { GetLandingFilteredProductsModel } from './../models/product.model';

export enum ProductActionsTypes {
  GetProducts = '[PRODUCT] GetProducts',
  GetProductsSuccess = '[PRODUCT] GetProductsSuccess',
  UpdateProductsWithEngagementInfo = '[PRODUCT] Update Product With Engagement Info',
  GetProductsFailure = '[PRODUCT] GetProductsFailure',
  GetProductById = '[PRODUCT] GetProductById',
  GetProductByIdSuccess = '[PRODUCT] GetProductByIdSuccess',
  GetProductByIdError = '[PRODUCT] GetProductByIdError',
  SetActiveProduct = '[PRODUCT] SetActiveProduct',
  SetActiveProductSuccess = '[PRODUCT] SetActiveProductSuccess',

  GetLandingFilteredProducts = '[PRODUCT] GetLandingFilteredProducts',
  GetLandingFilteredProductsSuccess = '[PRODUCT] GetLandingFilteredProductsSuccess',
  GetLandingFilteredProductsFailure = '[PRODUCT] GetLandingFilteredProductsFailure'
}

export const getProducts = createAction(ProductActionsTypes.GetProducts, props<GetProductsModel>());
export const getProductsSuccess = createAction(ProductActionsTypes.GetProductsSuccess, props<{ products: any }>());
export const updateProductsWithEngagementInfo = createAction(ProductActionsTypes.UpdateProductsWithEngagementInfo, props<{ products: any }>());
export const getProductsFailure = createAction(ProductActionsTypes.GetProductsFailure, props<GetProductModel>());
export const getProductById = createAction(ProductActionsTypes.GetProductById, props<GetProductModel>());
export const getProductByIdSuccess = createAction(ProductActionsTypes.GetProductByIdSuccess, props<ProductModel>());
export const getProductByIdError = createAction(ProductActionsTypes.GetProductByIdError);
export const setActiveProduct = createAction(ProductActionsTypes.SetActiveProduct, props<GetProductModel>());
export const setActiveProductSuccess = createAction(
  ProductActionsTypes.SetActiveProductSuccess,
  props<GetProductModel>()
);

export const getLandingFilteredProducts = createAction(
  ProductActionsTypes.GetLandingFilteredProducts,
  props<GetLandingFilteredProductsModel>()
);
export const getLandingFilteredProductsSuccess = createAction(
  ProductActionsTypes.GetLandingFilteredProductsSuccess,
  props<{ filteredLangindProducts: any }>()
);
export const getLandingFilteredProductsFailure = createAction(
  ProductActionsTypes.GetLandingFilteredProductsFailure,
  props<GetProductModel>()
);
3;
