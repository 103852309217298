import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CustomersHttp } from 'lib-core';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { AddressService } from '../../services/address.service';
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service';
import { ToastService } from '../../services/toast.service';
import {
  AddShippingAddressAttempt,
  AddShippingAddressFailed,
  AddShippingAddressSuccess,
  ChangeDefaultShippingAddressAttempt,
  ChangeDefaultShippingAddressFailed,
  ChangeDefaultShippingAddressSuccess,
  EditShippingAddressAttempt,
  EditShippingAddressFailed,
  EditShippingAddressSuccess,
  GetShippingAddressesAttempt,
  GetShippingAddressesFailed,
  GetShippingAddressesSuccess,
  RemoveShippginAddressAttempt,
  RemoveShippginAddressFailed,
  RemoveShippginAddressSuccess
} from '../actions/addresses.actions';
import { ShippingAddressInteface } from '../models/addresses.model';

@Injectable()
export class AddressesEffects {
  constructor(
    private actions$: Actions,
    private customersService: CustomersHttp,
    private addressService: AddressService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastService: ToastService
  ) {}

  getShippingAddressesAttempt = createEffect(() =>
    this.actions$.pipe(
      ofType(GetShippingAddressesAttempt),
      mergeMap(() =>
        this.customersService.getShippingAddresses().pipe(
          map((res: ShippingAddressInteface[]) => GetShippingAddressesSuccess({ data: res })),
          catchError(err => of(GetShippingAddressesFailed(err)))
        )
      )
    )
  );

  addShippingAddressAttempt = createEffect(() =>
    this.actions$.pipe(
      ofType(AddShippingAddressAttempt),
      mergeMap((data: { shippingAddress: ShippingAddressInteface; force?: boolean }) =>
        this.customersService.addShippingAddress(data.shippingAddress).pipe(
          map(res => {
            this.addressService.closeAddressFormModal();
            this.toastService.showToastEvent(null, 'მისამართი წარმატებით დაემატა', 'successLight');
            return AddShippingAddressSuccess({ res, force: data.force });
          }),
          catchError(err => {
            return of(AddShippingAddressFailed(err));
          })
        )
      )
    )
  );

  editShippingAddressAttempt = createEffect(() =>
    this.actions$.pipe(
      ofType(EditShippingAddressAttempt),
      mergeMap((data: ShippingAddressInteface) =>
        this.customersService.editShippingAddress(data).pipe(
          map(res => {
            this.addressService.closeAddressFormModal();
            this.toastService.showToastEvent(null, 'ცვლილება წარმატებით განხორციელდა', 'successLight');
            return EditShippingAddressSuccess(res);
          }),
          catchError(err => {
            return of(EditShippingAddressFailed(err));
          })
        )
      )
    )
  );

  removeShippginAddressAttempt = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveShippginAddressAttempt),
      mergeMap((data: { addressId: number }) =>
        this.customersService.removeShippingAddress(data.addressId).pipe(
          map(res => RemoveShippginAddressSuccess(res)),
          catchError(err => of(RemoveShippginAddressFailed(err)))
        )
      )
    )
  );

  changeDefaultShippingAddressAttempt = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeDefaultShippingAddressAttempt),
      mergeMap((data: { id: number; isDefault: boolean }) =>
        this.customersService.changeDefaultShippingAddress(data).pipe(
          map(res => ChangeDefaultShippingAddressSuccess(res)),
          catchError(err => of(ChangeDefaultShippingAddressFailed(err)))
        )
      )
    )
  );

  addressActionsOnSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AddShippingAddressSuccess,
        EditShippingAddressSuccess,
        RemoveShippginAddressSuccess,
        ChangeDefaultShippingAddressSuccess
      ),
      concatMap(data =>
        this.customersService.getShippingAddresses().pipe(
          map((res: ShippingAddressInteface[]) => {
            if (data?.force) {
              this.addressService.setSelectedAddressId(data.res);
            }
            this.confirmationDialogService.closeConfirmationModal();
            return GetShippingAddressesSuccess({ data: res });
          }),
          catchError(err => of(GetShippingAddressesFailed(err)))
        )
      )
    )
  );
}
