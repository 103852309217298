import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class ProfileHttp {
  constructor(private api: ApiService) {}

  adminUserCreate(data): Observable<any> {
    return this.api.post('api/Admin/User/Create', data);
  }

  getRoles(): Observable<any> {
    return this.api.get('api/Roles');
  }

  roleUpdate(data): Observable<any> {
    return this.api.put('api/Admin/User/Role-Update', data);
  }

  getEmployeesData(data): Observable<any> {
    return this.api.get(`api/Admin/User/Employees`, data);
  }

  adminUserResetPassword(data): Observable<any> {
    return this.api.post('api/Admin/User/ResendTemporaryPassword', data);
  }

  getUserSignUoValidation(data): Observable<any> {
    return this.api.post('api/Account/sendverification', data, { requestId: this.getGuidId() });
  }

  validateMerge(sourceUserName: string): Observable<any> {
    return this.api.get(`api/Account/validate-merge`, { sourceUserName });
  }

  getUserPasswordResetToken(data): Observable<any> {
    return this.api.post('api/Account/sendpasswordresetcode', data, { requestId: this.getGuidId() });
  }

  deleteUser(id): Observable<any> {
    return this.api.delete('api/Admin/User/Delete', {
      id: id
    });
  }

  getPasswordResetToken(data): Observable<any> {
    return this.api.post('api/Account/sendpasswordresetcode', data, { requestId: this.getGuidId() });
  }

  addUserInfo(data): Observable<any> {
    return this.api.post('api/Account/register', data, { requestId: this.getGuidId() });
  }

  addUserSignUoValidation(data): Observable<any> {
    return this.api.post('api/Account/verify', data, { requestId: this.getGuidId() });
  }

  addUserSingUpPassword(data): Observable<any> {
    return this.api.post('api/Account/setpassword', data, { requestId: this.getGuidId() });
  }

  updateUserResetPassword(data): Observable<any> {
    return this.api.post('api/Account/setpassword', data, { requestId: this.getGuidId() });
  }

  removeOldPassword(data): Observable<any> {
    return this.api.post('api/Account/resetpassword', data, { requestId: this.getGuidId() });
  }

  resetPasswordToken(userId: string): Observable<any> {
    return this.api.post('api/Profile/SendResetPasswordToken', { id: userId }, {});
  }

  userDevAuthorization(username: string, password: string): Observable<any> {
    return this.api.post('api/Admin/User/Authorize', { username, password });
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}
