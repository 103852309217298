import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, RendererFactory2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LocalStorageService, MixpanelHttpService } from 'lib-core';
import { CookieService } from 'ngx-cookie-service';
import { map, Observable, skip } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserModel } from '../store/models/user.model';
import { getUserBasket } from '../store/reducers/basket.reducer';
import { getUserData } from '../store/reducers/user.reducer';
import * as fromAuth from './../store/models/auth.model';
import * as fromUser from './../store/models/user.model';

export enum DYEvents {
  HomePageView,
  ProductPageView,
  CategoryPageView,
  KeyWordSearch,
  BasketPageView,
  AddToBasket,
  RemoveFromBasket,
  SyncBasket,
  UserLogIn,
  UserSignUp,
  UserSync,
  WishlistUpdate,
  PromoCodeEntered,
  SortItems,
  FilterItems,
  ChangeAttribute
}

declare global {
  interface Window {
    DY: any;
  }
}

interface FilterObjectNumeric {
  filterType: string;
  filterNumericValue: number;
}

interface FilterObjectString {
  filterType: string;
  filterStringValue: string;
}

type FilterObject = FilterObjectNumeric | FilterObjectString;

@Injectable({
  providedIn: 'root'
})
export class DynamicYieldService {
  private events: { name: DYEvents; args: any[] }[] = [];
  private scriptLoaded: boolean = false;
  user$: Observable<UserModel> = this.store.pipe(select(getUserData));
  private userExternalId = null;
  public userRegisered: boolean = false;
  private dyId: string;
  basket$ = this.store.pipe(select(getUserBasket));
  basket;
  // TO BE CONSIDERED
  // events are triggered only on category and search page views. in case of merchant's it does not, also not for sets. could call for the categorypage view and send SETID but it could be duplicate?!

  constructor(
    private rendererFactory: RendererFactory2,
    private store: Store<fromAuth.AuthModel | fromUser.UserModel>,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private mixpanelHttp: MixpanelHttpService,
    private cookieService: CookieService
  ) {
    if (environment.production) {
      this.subscribeToUser();
      this.subscribeToBasket();
    }
  }

  private subscribeToUser(): void {
    this.user$.subscribe((userData: UserModel) => {
      if (userData?.userExternalId) {
        this.userExternalId = userData.userExternalId;
        this.enqueueEvent(DYEvents.UserSync, {
          userExternalId: this.userExternalId
        });
        if (this.localStorageService.getValue('isSocLoginForDynamicYield') == 'true') {
          this.enqueueEvent(DYEvents.UserLogIn, {
            userExternalId: this.userExternalId
          });
          setTimeout(() => {
            this.localStorageService.removeItem('isSocLoginForDynamicYield');
          }, 1000);
        }
      }
      // if (userData && Object.keys(userData).length === 0) {
      //   this.enqueueEvent(DYEvents.UserLogIn, {
      //     userId: this.userId,
      //     userLoginStatus: false
      //   });
      // }
    });
  }

  private subscribeToBasket(): void {
    this.basket$.pipe(skip(2)).subscribe(basketData => {
      this.basket = basketData.data;
      // this.enqueueEvent(DYEvents.basketUpdateAndView, {});
    });
  }

  /**
   * Sets the DY recommendation context
   * @param type - The type of context (e.g., "HOMEPAGE")
   * @param data - A string array of data (optional if not used)
   */
  private setDYContext(type: string, data?: string[]) {
    window.DY = window.DY || {};
    window.DY.recommendationContext = { type, data };
  }

  private userLogIn(args: { userExternalId: string; userLoginStatus: boolean }): void {
    if (this.userRegisered) {
      this.enqueueEvent(DYEvents.UserSignUp, {
        userExternalId: args.userExternalId
      });
      this.userRegisered = false;
    }
    const data = {
      name: 'login',
      properties: {
        dyType: 'login-v1',
        cuid: args.userExternalId,
        cuidType: 'userExternalId'
      }
    };
    window.DY.API('event', data);
  }

  private userSignUp(args: { userExternalId: string }): void {
    const data = {
      name: 'sign_up',
      properties: {
        dyType: 'signup-v1',
        cuid: args.userExternalId,
        cuidType: 'userExternalId'
      }
    };
    window.DY.API('event', data);
  }

  private userSync(args: { userExternalId: string }): void {
    const data = {
      name: 'identify_user',
      properties: {
        dyType: 'identify-v1',
        cuid: args.userExternalId,
        cuidType: 'userExternalId'
      }
    };
    window.DY.API('event', data);
  }

  private keyWordSearch(args: { searchKeyword: string }): void {
    const data = {
      name: 'keyword_search',
      properties: {
        dyType: 'keyword-search-v1',
        keywords: args.searchKeyword
      }
    };
    window.DY.API('event', data);
  }
  private updateBasket(actionType, args: any): void {
    //in both cases when basket is viewed or updated this method is triggered
    const data = {
      name: actionType === DYEvents.AddToBasket ? 'add_to_cart' : 'remove_from_cart',
      properties: {
        ...args,
        dyType: actionType === DYEvents.AddToBasket ? 'add-to-cart-v1' : 'remove-from-cart-v1',

        cart: this.basket
          ?.filter(product => product.productId !== args.productId)
          .map(product => {
            return {
              productId: String(product.productId),
              quantity: product.productCount,
              itemPrice: product.discountedPrice || product.price
            };
          })
      }
    };
    window.DY.API('event', data);
  }

  private syncBasket(): void {
    //in both cases when basket is viewed or updated this method is triggered
    const data = {
      name: 'sync_cart',
      properties: {
        dyType: 'sync-cart-v1',

        cart: this.basket?.map(item => {
          return {
            productId: String(item.productId),
            quantity: item.productCount,
            itemPrice: item.price
          };
        }),
        // Sync cart => Sync-cart event does not have "value" property
        value: this.basket?.reduce((acc, item) => acc + item.price * item.productCount, 0)
      }
    };
    window.DY.API('event', data);
  }

  private wishlistUpdate(args: { productId: string }): void {
    const data = {
      name: 'add_to_wishlist',
      properties: {
        dyType: 'add-to-wishlist-v1',
        productId: String(args.productId)
      }
    };
    window.DY.API('event', data);
  }

  private promoCodeEntered(args: { code: string }): void {
    const data = {
      name: 'promo_code_entered',
      properties: {
        dyType: 'enter-promo-code-v1',
        code: args.code
      }
    };
    window.DY.API('event', data);
  }

  private sortItems(args: { sortBy: string; sortOrder: string }): void {
    const data = {
      name: 'sort_items',
      properties: {
        dyType: 'sort-items-v1',
        ...args
      }
    };
    window.DY.API('event', data);
  }
  private filterItems(args: { filterType: string; value: string | number }): void {
    let filterArgs = this.createFilterObject(args);
    const data = {
      name: 'filter_items',
      properties: {
        dyType: 'filter-items-v1',
        ...filterArgs
      }
    };
    window.DY.API('event', data);
  }

  private createFilterObject(args: { filterType: string; value: any }): FilterObject {
    const filterType = args.filterType || 'unknown';

    const isNumeric = !isNaN(args.value);

    if (isNumeric) {
      return {
        filterType: filterType,
        filterNumericValue: args.value
      };
    } else {
      return {
        filterType: filterType,
        filterStringValue: args.value
      };
    }
  }
  private changeAttribute(args: { attributeType: string; attributeValue: string }): void {
    const data = {
      name: 'change_attribute',
      properties: {
        dyType: 'change-attr-v1',
        ...args
      }
    };
    window.DY.API('event', data);
  }

  public engagementClicks(params: {slotId: string, variations: number[]}): void {
    const dyIds = this.getDyIds();

    const data = {
      user: {
        dyid: dyIds.dyId,
        dyid_server: dyIds.dyIdServer,
        active_consent_accepted: true
      },
      session: {
        dy: dyIds.dySession
      },
      context: {
        channel: 'WEB'
      },
      engagements: [{
        type: 'SLOT_CLICK',
        slotId: params.slotId,
      }]
    };
    this.mixpanelHttp.engageSlotClickDy(data, environment.dynamicYieldApiKey).subscribe();
  }

  public getRecommendation(selector: {
    groups?: string[];
    page?: { type: string; data: string[]; location: string };
  }): Observable<{ name: string; productIds: number[] }[]> {
    const dyIds = this.getDyIds();

    const data = {
      options: {
        isImplicitClientData: true,
        recsProductData: {
          skusOnly: true
        }
      },
      user: {
        dyid: dyIds.dyId,
        dyid_server: dyIds.dyIdServer,
        active_consent_accepted: true
      },
      session: {
        dy: dyIds.dySession
      },
      selector: {
        groups: selector.groups
      },
      context: {
        page: selector.page,
        channel: 'WEB'
      }
    };

    // Return the transformed observable
    return this.fetchRecommendations(data);
  }

  private fetchRecommendations(payload: any): Observable<{ name: string; productIds: number[] }[]> {
    return this.mixpanelHttp.getRecommendations(payload, environment.dynamicYieldApiKey).pipe(
      map(response => {
        // For each choice, build an object { name, productIds }
        // productIds is an array of numeric SKUs
        return response?.choices?.flatMap(choice => {
          return choice?.variations?.map(variation => {
            return {
              productIds: variation?.payload?.data?.slots?.map(slot => Number(slot.sku)),
              name: variation?.payload?.data?.custom?.Title || 'რეკომენდაცია',
              engagements: variation?.payload?.data?.slots.map(slot => ({
                  slotId: slot.slotId,
                  id: Number(slot.sku),
                  variations: [variation?.id]
              }))
            };
          });
        });
      })
    );
  }

  // ---------------------------------------------------------------------------------------- evenet handling
  public enqueueEvent(name: DYEvents, args?: any) {
    if (!environment.production) {
      return;
    }
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.handleEvent(name, args);
  }

  private getCookie(name: string): string | null {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? decodeURIComponent(match[2]) : null;
  }

  public getDyIds() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    return {
      dyId: this.dyId || localStorage.getItem('_dyid'),
      dyIdServer: this.dyId || localStorage.getItem('_dyid'),
      activeConsentAccepted: true,
      dySession: this.getCookie('_dyjsession')
    };
  }

  private handleEvent(name: DYEvents, args: any) {
    switch (name) {
      case DYEvents.HomePageView:
        this.setDYContext('HOMEPAGE');
        break;
      case DYEvents.ProductPageView:
        this.setDYContext('PRODUCT', args);
        break;
      case DYEvents.CategoryPageView:
        this.setDYContext('CATEGORY', args);
        break;
      case DYEvents.KeyWordSearch:
        this.keyWordSearch(args);
        break;
      case DYEvents.BasketPageView:
        this.setDYContext('CART', args);
        break;
      case DYEvents.UserLogIn:
        this.userLogIn(args);
        break;
      case DYEvents.UserSignUp:
        this.userSignUp(args);
        break;
      case DYEvents.UserSync:
        this.userSync(args);
        break;
      case DYEvents.AddToBasket:
        this.updateBasket(DYEvents.AddToBasket, args);
        break;
      case DYEvents.RemoveFromBasket:
        this.updateBasket(DYEvents.RemoveFromBasket, args);
        break;
      case DYEvents.SyncBasket:
        this.syncBasket();
        break;
      case DYEvents.WishlistUpdate:
        this.wishlistUpdate(args);
        break;
      case DYEvents.PromoCodeEntered:
        this.promoCodeEntered(args);
        break;
      case DYEvents.SortItems:
        this.sortItems(args);
        break;
      case DYEvents.FilterItems:
        this.filterItems(args);
        break;
      case DYEvents.ChangeAttribute:
        this.changeAttribute(args);
        break;
      default:
    }
  }

  public initialChooseRequestFordyjsession() {
    const dyIds = this.getDyIds();

    if (dyIds.dySession) {
      return;
    }

    const data = {
      options: {
        isImplicitClientData: true,
        recsProductData: {
          skusOnly: true
        }
      },
      user: {
        dyid: dyIds.dyId,
        dyid_server: dyIds.dyIdServer,
        active_consent_accepted: true
      },
      session: {
        dy: dyIds.dySession
      },
      selector: {
        names: []
      },
      context: {
        page: {
          type: 'OTHER',
          data: [],
          location: 'initial'
        },
        channel: 'WEB'
      }
    };
    this.mixpanelHttp.getRecommendations(data, environment.dynamicYieldApiKey).subscribe(response => {
      const dyjsession = response?.cookies?.find(cookie => cookie.name === '_dyjsession')?.value;
      this.dyId = response?.cookies?.find(cookie => cookie.name === '_dyid_server')?.value;
      if (dyjsession) {
        this.cookieService.set('_dyjsession', dyjsession);
      }
    });
  }
}
