import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { BasketHttp, Unsubscriber } from 'lib-core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CheckoutCommonService } from '../../pages/protected-routes/checkout/services/checkout-common.service';
import { ExpressService } from '../../services/express.service';
import {
  checkLocalBasket,
  clearBasket,
  clearBasketSuccess,
  deleteBasket,
  deleteBasketFail,
  deleteBasketSuccess,
  getBasket,
  getBasketSuccess,
  getCheckOutBasket,
  getCheckOutBasketFail,
  getCheckOutBasketSuccess,
  updateBasket,
  updateBasketMultiple,
  updateBasketSuccess,
  updateUserBasketMultiplev2
} from '../actions/basket.actions';
import * as fromAuth from '../models/auth.model';
import { BasketCheckOutModel, BasketModel, BasketUpdateModel } from '../models/basket.model';
import { ShippingOptionType } from '../models/orders.model';
import { getUserStatus } from '../reducers/auth.reducer';
import { DYEvents, DynamicYieldService } from '../../services/dynamic-yield.service';

const key = 'basket'; // local storage key
const initialBasketObj = {
  products: []
};
const ReffererType = '0';

const reduceLocalBasketData = (product, remove = false) => {
  const data = localStorage.getItem(key);
  const { products } = data ? JSON.parse(data) : initialBasketObj;

  let newProducts = [];

  let productWasFound = false;
  let oldProducts = [];
  if (product.length) {
    for (const el1 of products) {
      for (const el2 of product) {
        if (el2.productId === el1.productId) {
          if (remove) continue;
          el2.productCount += el1.productCount;
          productWasFound = true;
        } else {
          oldProducts.push(el1);
        }
        newProducts.push(el2);
      }
    }

    if (oldProducts.length) {
      newProducts.push(...oldProducts);
    }

    const uniqueArr = newProducts.filter(
      (obj, index, self) => index === self.findIndex(o => o.productId === obj.productId)
    );
    newProducts = uniqueArr;
  } else {
    for (const el of products) {
      if (product.productId === el.productId) {
        if (remove) continue;
        //if( product.append ){
        //el.productCount += product.productCount;
        //}else{
        el.productCount = product.productCount;
        //}
        productWasFound = true;
      }
      newProducts.push(el);
    }
  }

  if (!productWasFound && !remove) {
    if (product.length) {
      newProducts.push(...product);
    } else {
      newProducts.push(product);
    }
  }

  const response = {
    ...initialBasketObj,
    products: [...newProducts]
  };

  return response;
};

const saveLocalBasetData = data => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getLocalBasketData = platformId => {
  let basketObj = initialBasketObj;
  if (isPlatformBrowser(platformId)) {
    const data = localStorage.getItem(key);
    try {
      basketObj = JSON.parse(data);
    } catch (err) {}
  }
  return basketObj;
};

@Injectable()
export class BasketEffects extends Unsubscriber {
  userStatus$: Observable<any> = this.store.pipe(select(getUserStatus));
  userStatus;
  constructor(
    private actions$: Actions,
    private basketHttp: BasketHttp,
    private store: Store<fromAuth.AuthModel>,
    @Inject(PLATFORM_ID) private platformId: Object,
    private expressService: ExpressService,
    private route: ActivatedRoute,
    private checkoutCommonService: CheckoutCommonService,
    private dynamicYieldService: DynamicYieldService
  ) {
    super();
    this.userStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.userStatus = status;
    });
  }

  getBasket = createEffect(() =>
    this.actions$.pipe(
      ofType(getBasket),
      switchMap(() => {
        if (this.userStatus) {
          return this.basketHttp.getUserBasket(this.expressService.getDarkStoreData()?.darkStoreId);
        }
        return this.basketHttp.getAnonymousUserBasket(
          getLocalBasketData(this.platformId),
          this.expressService.getDarkStoreData()?.darkStoreId
        );
      }),
      map((data: BasketModel) => getBasketSuccess(data))
    )
  );

  checkLocalBasket = createEffect(() =>
    this.actions$.pipe(
      ofType(checkLocalBasket),
      switchMap(() => {
        const basket = getLocalBasketData(this.platformId);
        if (basket && basket.products && basket.products.length) {
          return this.basketHttp.updateUserBasketMultiple({
            basketItems: [...basket.products],
            darkStoreId: this.expressService.getDarkStoreData()?.darkStoreId
          });
        }
        return of();
      }),
      map((data: any) => {
        localStorage.setItem(key, JSON.stringify(initialBasketObj));
        return getBasketSuccess(data);
      })
    )
  );

  getBasketCheckOut = createEffect(() =>
    this.actions$.pipe(
      ofType(getCheckOutBasket),
      switchMap((data: BasketCheckOutModel) => {
        this.expressService.setExpressProductsLoadingState(true);
        (data.darkStoreId =
          this.expressService.getDarkStoreData()?.isDefault ||
          this.checkoutCommonService.getSelectedShippingOptionType() === ShippingOptionType.Pickup
            ? null
            : this.expressService.getDarkStoreId()),
          (data.districtId =
            this.checkoutCommonService.getSelectedShippingOptionType() === ShippingOptionType.Pickup
              ? null
              : this.expressService.getDarkStoreData()?.districtId);
        // data.removeInactiveProducts &&
        if (!this.route.snapshot.queryParams?.productId) {
          data.items = data.items.filter(product => {
            const isProductActive = product.isActive || product?.['isActiveProduct'];
            return (
              (this.checkoutCommonService.getSelectedShippingOptionType() === ShippingOptionType.Courier &&
                isProductActive) ||
              (this.checkoutCommonService.getSelectedShippingOptionType() === ShippingOptionType.Pickup &&
                ((!isProductActive && product.isExpress) || product.shippingPackageSizeId !== 5))
            );
          });
        }
        return this.basketHttp.getUserCheckOutBasket(data).pipe(
          map(res => {
            // res['data'].items = data.items;
            return {
              res: res && res['data'],
              params: {
                ...data
              },
              succes: true
            };
          }),
          catchError(err => of(getCheckOutBasketFail(err))),

          switchMap((data: any) => {
            if (!data.succes) {
              return of(null);
            }

            const isOnlyExpressProducts = !data.res.products.length && data.res.express?.expressProducts?.length;
            const mixedProducts = data?.res?.products?.length && data?.res?.express?.expressProducts?.length;
            return this.basketHttp
              .getCampaignInformation({
                //DiscountType: DiscountType.MasterCard,
                cartTotalAmount: data.res.productTotalPrice,
                ReferrerType: ReffererType,
                ShippingOptionType: data.params.shippingOptionType,
                merchantIds: data?.params?.merchantIds || []
              })
              .pipe(
                map(res => {
                  this.expressService.setExpressProductsLoadingState(false);
                  return {
                    ...data.res,
                    // if theres only express products, we don't show campaign informations
                    discountRules: isOnlyExpressProducts ? res?.filter((campaignInfo) => campaignInfo?.isCashBackCampaign) : [...res],
                    isCampaignAndMixedProducts: res?.length && mixedProducts
                  };
                })
              );

            return of(data.res);
          })
        );
      }),
      map((data: BasketCheckOutModel) => getCheckOutBasketSuccess(data))
    )
  );

  updateBasket = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBasket),
      mergeMap((addedProduct: BasketUpdateModel) => {
        if (this.userStatus) {
          return this.basketHttp
            .updateUserBasket(addedProduct, this.expressService.getDarkStoreData()?.darkStoreId)
            .pipe(
              mergeMap(res => {
                return of({
                  res: res,
                  addedProduct
                });
              }),
              tap(() => {
                if (addedProduct.shouldUpdateBasketExpressProductsTriggered) {
                  this.expressService.updateProducts();
                }
              })
            );
        }
        const reducedData = reduceLocalBasketData(addedProduct);

        return this.basketHttp
          .getAnonymousUserBasket(reducedData, this.expressService.getDarkStoreData()?.darkStoreId)
          .pipe(
            mergeMap(res => {
              return of({
                res: res,
                reducedData: reducedData,
                addedProduct
              });
            }),
            tap(() => {
              if (addedProduct.shouldUpdateBasketExpressProductsTriggered) {
                this.expressService.updateProducts();
              }
            })
          );
      }),
      map((obj: any) => {
        setTimeout(() => {
          const addedAmount = obj?.addedProduct?.productCount - obj?.addedProduct?.productPreviousCount || 1;
          if (addedAmount > 0) {
            let dataWrapper = {
              value:
                (obj?.addedProduct?.discountType?.discountedPrice ||
                  obj?.res?.data.find(el => el.productId === obj?.addedProduct?.productId)?.price) * addedAmount,
              productId: String(obj.addedProduct.productId),
              quantity: addedAmount
            };
            const size = obj?.res?.data
              ?.find(item => item?.productId === obj?.addedProduct?.productId)
              ?.details?.find(el => el.key === 'sizes')?.value;

            if (size) {
              dataWrapper['size'] = size;
            }
            this.dynamicYieldService.enqueueEvent(DYEvents.AddToBasket, dataWrapper);
          } else {
            let dataWrapper = {
              productId: String(obj.addedProduct.productId),
              value:
                (obj?.addedProduct?.discountType?.discountedPrice ||
                  obj?.res?.data.find(el => el.productId === obj?.addedProduct?.productId)?.price) *
                addedAmount *
                -1,
              quantity: addedAmount * -1
            };
            const size = obj.addedProduct.discountType?.variationTypes?.sizes?.featureValues?.find(
              element => element?.isDefault
            )?.value;
            if (size) {
              dataWrapper['size'] = size;
            }
            this.dynamicYieldService.enqueueEvent(DYEvents.RemoveFromBasket, dataWrapper);
          }
        }, 0);
        if (obj.reducedData) {
          saveLocalBasetData(obj.reducedData);
        }
        return updateBasketSuccess(obj.res);
      })
    )
  );
  updateBasketMultiple = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserBasketMultiplev2),
      mergeMap((data: any) => {
        if (this.userStatus) {
          return this.basketHttp
            .updateUserBasketMultiple({
              basketItems: data.arr,
              darkStoreId: this.expressService.getDarkStoreData()?.darkStoreId
            })
            .pipe(
              mergeMap(res => {
                return of({
                  res: res,
                  item: data
                });
              })
            );
        }
        let reducedData: {
          products: any[];
        };
        let localBascet = reduceLocalBasketData(data.arr);

        reducedData = {
          products: localBascet?.products
        };
        return this.basketHttp
          .getAnonymousUserBasket(reducedData, this.expressService.getDarkStoreData()?.darkStoreId)
          .pipe(
            mergeMap(res => {
              return of({
                res: res,
                reducedData: reducedData,
                item: data
              });
            })
          );
      }),
      map((obj: any) => {
        setTimeout(() => {
          obj.item.arr.forEach(product => {
            let dataWrapper = {
              value: (product.discountedPrice || product.sellPrice) * product.productCount,
              productId: String(product.productId),
              quantity: product.productCount
            };
            // TODO might need to add sizes options here
            this.dynamicYieldService.enqueueEvent(DYEvents.AddToBasket, dataWrapper);
          });
        }, 0);
        if (obj.reducedData) {
          saveLocalBasetData(obj.reducedData);
        }
        return updateBasketSuccess(obj.res);
      })
    )
  );

  updateBasketFromDetailed = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBasketMultiple),
      mergeMap((data: any) => {
        if (this.userStatus) {
          return this.basketHttp
            .updateUserBasketMultiplev2({
              basketItems: [data],
              darkStoreId: this.expressService.getDarkStoreData()?.darkStoreId
            })
            .pipe(
              mergeMap(res => {
                return of({
                  res: res,
                  item: data
                });
              })
            );
        }
        let reducedData: {
          products: any[];
        };
        let localBascet = reduceLocalBasketData(data);

        reducedData = {
          products: localBascet?.products
        };
        return this.basketHttp
          .getAnonymousUserBasket(reducedData, this.expressService.getDarkStoreData()?.darkStoreId)
          .pipe(
            mergeMap(res => {
              return of({
                res: res,
                reducedData: reducedData,
                item: data
              });
            })
          );
      }),
      map((obj: any) => {
        if (obj.reducedData) {
          saveLocalBasetData(obj.reducedData);
        }
        setTimeout(() => {
          let dataWrapper = {
            value:
              (obj.item.discountType?.discountedPrice ||
                obj.res?.data.find(el => el.productId === obj.item.productId)?.price) * obj.item.productCount,
            productId: String(obj.item.productId),
            quantity: obj.item.productCount
          };
          const size = obj.item.discountType?.variationTypes?.sizes?.featureValues?.find(
            element => element?.isDefault
          )?.value;
          if (size) {
            dataWrapper['size'] = size;
          }
          this.dynamicYieldService.enqueueEvent(DYEvents.AddToBasket, dataWrapper);
        }, 0);
        return updateBasketSuccess(obj.res);
      })
    )
  );
  deleteBasket = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteBasket),
      switchMap((data: BasketUpdateModel) => {
        data.darkStoreId = this.expressService.getDarkStoreData()?.darkStoreId;
        if (this.userStatus) {
          return this.basketHttp.deleteBasketProduct(data).pipe(
            mergeMap(res => {
              return of({
                res: res,
                item: data
              });
            }),
            catchError(error => {
              return of(deleteBasketFail(error));
            })
          );
        }
        const reducedData = reduceLocalBasketData(data, true);
        return this.basketHttp
          .getAnonymousUserBasket(reducedData, this.expressService.getDarkStoreData()?.darkStoreId)
          .pipe(
            mergeMap(res => {
              return of({
                res: res || { data: [] },
                reducedData: reducedData,
                item: data
              });
            }),
            catchError(error => {
              return of(deleteBasketFail(error));
            })
          );
      }),
      map((obj: any) => {
        if (obj.reducedData) {
          saveLocalBasetData(obj.reducedData);
        }

        if (!(obj.res && obj.res.data.length > 0) && obj?.res) {
          obj.res.quantityFailed = false;
          obj.res.totalPrice = 0;
        }
        setTimeout(() => {
          // value: obj.item.discountType?.discountedPrice,
          let dataWrapper = {
            value: (obj.item.discountedPrice || obj.item.price) * obj.item.productCount,
            productId: String(obj.item.productId),
            quantity: obj.item.productCount
          };
          const size = obj.item.discountType?.variationTypes?.sizes?.featureValues?.find(
            element => element?.isDefault
          )?.value;
          if (size) {
            dataWrapper['size'] = size;
          }
          this.dynamicYieldService.enqueueEvent(DYEvents.RemoveFromBasket, dataWrapper);
        }, 0);
        return deleteBasketSuccess(obj.res);
      }),
      catchError(error => {
        return of(deleteBasketFail(error));
      })
    )
  );

  clearBasket = createEffect(() =>
    this.actions$.pipe(
      ofType(clearBasket),
      switchMap(() => {
        if (this.userStatus) {
          return this.basketHttp.clearBasketProduct(this.expressService.getDarkStoreData()?.darkStoreId);
        }
        return of();
      }),
      map((res: any) => clearBasketSuccess(res))
    )
  );
}

interface dataInterface {
  data: any;
  totalCount: number;
}
