import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MarketingHttp } from 'lib-core';
import { BehaviorSubject, interval, pipe } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { GlobalModalService } from '../../services/global-modal-service';
import { OverflowHandlerService } from '../../services/overflow-handler.service';
import {
  NotificationPopupByDate,
  NotificationPopupByType,
  PlatformType
} from '../../store/models/notification-popups.model';
import { NotificationPopupMainComponent } from './notification-popup-main.component';
@Injectable({
  providedIn: 'root'
})
export class NotificationPopupMainService {
  public showNotificationPopup = new BehaviorSubject(false);

  showSuccessPopup$ = new BehaviorSubject<boolean>(false);

  public popupTypesByDate = NotificationPopupByDate;
  public popupTypesByType = NotificationPopupByType;
  public popupsArray$ = new BehaviorSubject([]);
  public currentPopupObj$ = new BehaviorSubject({});
  public timeCounter = 1000;
  public popupsCount: number = 0;
  public showNotificationPopup$ = new BehaviorSubject(false);
  form: any;
  private successPageUrl = '/checkout/success';
  private currentUrl$ = new BehaviorSubject('');
  private isSuccessPage: boolean = false;
  private popupsInterval: any;
  private activeSessionDate: number;
  private platformType: number;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private marketingHttp: MarketingHttp,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private overflowHandlerService: OverflowHandlerService,
    private globalModalService: GlobalModalService
  ) {}
  init() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth >= 768) {
        this.platformType = PlatformType.Desktop;
      } else {
        this.platformType = PlatformType.Mobile;
      }

      if (!sessionStorage.getItem('dateNow')) {
        // start session date
        sessionStorage.setItem('dateNow', `${Date.now()}`);
      }
    }

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationStart))
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          if (this.popupsInterval) {
            this.popupsInterval.unsubscribe(); // when router changes, interval must be unsubscribe to avoid multiple intervals
            this.timeCounter = 1000; // time counter have to start all over again
            if (isPlatformBrowser(this.platformId)) {
              this.activeSessionDate = isNaN(+sessionStorage.getItem('dateNow'))
                ? null
                : +sessionStorage.getItem('dateNow');
            }
          }
        }
        if (event instanceof NavigationEnd) {
          this.currentUrl$.next(this.router.url.split('?')[0]);
        }
      });
    if (isPlatformBrowser(this.platformId)) {
      this.activeSessionDate = isNaN(+sessionStorage.getItem('dateNow')) ? null : +sessionStorage.getItem('dateNow');
    }

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.marketingHttp
      .getPopups(this.platformType)
      .pipe(
        map(res => res['data']),
        tap(res => {
          this.handlePopupsData(res);
        })
      )
      .subscribe(() => {});

    if (isPlatformBrowser(this.platformId)) {
      this.popupsArray$.pipe(filter(res => res.length !== 0)).subscribe(result => {
        let popupsInSeconds = [];
        let timeAfterRefresh = this.activeSessionDate ? Date.now() - this.activeSessionDate : 0; // how many seconds left from first session after user refresh
        for (let i = 0; i < result.length; i++) {
          const element = result[i];
          if (element.type === this.popupTypesByDate.OnSuccessPage) {
            element['seconds'] = 2; // popup on successpage doesn't have delay
            popupsInSeconds.push(element);
          } else {
            let seconds =
              element.type === this.popupTypesByDate.BySession
                ? this.millisecondsToSeconds(result[i].milliseconds - timeAfterRefresh)
                : this.millisecondsToSeconds(result[i].milliseconds); // convert remaining milliseconds to seconds for better calculations
            element['seconds'] = seconds;
            element.type === this.popupTypesByDate.Daily && (element.milliseconds = null); // პოპაპის ჩახურვის შემდეგ სხვა ფეიჯებზე ნავიგაციის დროს თავიდან რომ აღარ ამოხტეს
            seconds >= 0 ? popupsInSeconds.push(element) : null;
          }
        }
        if (!this.isSuccessPage && isPlatformBrowser(this.platformId)) {
          let allPopups = parseInt(sessionStorage.getItem('popupsCount')); // get already showed popups count
          if (popupsInSeconds.length !== 0) {
            // if all popups are showed , dont start interval
            this.popupsInterval = interval(1000).subscribe(() => {
              let currentPopup = popupsInSeconds.find(
                obj => obj.seconds == this.millisecondsToSeconds(this.timeCounter)
              ); // get popup which popup is next
              if (currentPopup) {
                this.openNotificationPopup();
                this.currentPopupObj$.next(currentPopup);
                this.globalModalService.open(NotificationPopupMainComponent, {});

                allPopups ? (this.popupsCount = allPopups) : this.popupsCount; //if page has been refreshed get correct popups count
                this.popupsCount++;
                sessionStorage.setItem('popupsCount', `${this.popupsCount}`);
                // this.cdr.markForCheck();
              }

              this.timeCounter += 1000;
              if (allPopups === popupsInSeconds.length || this.popupsCount === popupsInSeconds.length) {
                // check if all popups are showed
                this.popupsInterval.unsubscribe();
              }
            });
          }
        } else {
          let counter = 0;
          let stream = interval(1000).subscribe(() => {
            let currentPopup = popupsInSeconds.find(obj => obj.seconds == this.millisecondsToSeconds(this.timeCounter)); // get popup which popup is next
            if (currentPopup) {
              this.openNotificationPopup();
              this.currentPopupObj$.next(currentPopup);
              this.globalModalService.open(NotificationPopupMainComponent, {});

              counter++;
              // this.cdr.markForCheck();
            }
            this.timeCounter += 1000;
            if (counter === popupsInSeconds.length) {
              // check if all popups are showed
              stream.unsubscribe();
            }
          });
        }
      });
    }
  }
  millisecondsToSeconds(mill) {
    let seconds = Math.floor(mill / 1000);

    return seconds;
  }

  handlePopupsData(data) {
    this.currentUrl$
      .pipe(startWith(this.router.url.split('?')[0]))
      // .pipe(
      //    filter(res => res !== '') // home ფეიჯზე არ მუშაობდა და ავაკომენტარე
      // )
      .subscribe(val => {
        if (val === this.successPageUrl) {
          this.isSuccessPage = true;
          this.popupsArray$.next(data.filter(res => res.type == this.popupTypesByDate.OnSuccessPage));
        } else {
          this.isSuccessPage = false;
          this.popupsArray$.next(
            data.filter(res => res.type !== this.popupTypesByDate.OnSuccessPage && res.milliseconds !== null)
          );
        }
      });
  }

  closeNotificationPopup() {
    this.showNotificationPopup$.next(false);
    this.globalModalService.close();
    this.toggleScroll(true);
  }

  openNotificationPopup(): void {
    this.showNotificationPopup$.next(true);
    this.toggleScroll(false);
  }

  showSuccessPopup(val) {
    this.showSuccessPopup$.next(val);
    this.showNotificationPopup$.next(false);
  }

  closeSuccessPopup(): void {
    this.showSuccessPopup$.next(false);
    this.globalModalService.close();
    this.toggleScroll(true);
  }

  private toggleScroll(show) {
    if (show) {
      this.overflowHandlerService.show();
    } else {
      this.overflowHandlerService.hide();
    }
  }

  // -----
  showModal() {
    this.showNotificationPopup.next(true);
  }

  hideModal() {
    this.showNotificationPopup.next(false);
  }

  startSession() {
    if (isPlatformBrowser(this.platformId) && !sessionStorage.getItem('dateNow')) {
      // start session date
      sessionStorage.setItem('dateNow', `${Date.now()}`);
    }
  }
}
