import { createAction, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  GetMerchantMammaMia,
  GetMerchantCategoriesSuccess,
  GetMerchantMammaMiaSuccess,
  GetMerchantMammaMiaFail,
  ResetMerchantMammaMia
} from '../actions/merchant.actions';

const initialState = {
  data: [],
  mammaMia: null,
  loading: false
};

const MerchantReducer = createReducer(
  initialState,
  on(GetMerchantCategoriesSuccess, (state: object, action: any) => ({ ...state, ...action })),

  on(GetMerchantMammaMia, (state: object) => ({ ...state, loading: true }) as any),
  on(
    GetMerchantMammaMiaSuccess,
    (state: object, action: any) => ({ ...state, mammaMia: action.data, loading: false }) as any
  ),
  on(GetMerchantMammaMiaFail, (state: object) => ({ ...state, loading: false }) as any),
  on(ResetMerchantMammaMia, state => ({ ...state, mammaMia: null }))
);

export function reducer(state, action) {
  return MerchantReducer(state, action);
}

export const selectMerchantCategoriesFeatureState = createFeatureSelector('merchant');
export const selectMerchantCategoriesSuccess = createSelector(selectMerchantCategoriesFeatureState, items => items);

export const selectMerchantMammaMia = createSelector(
  selectMerchantCategoriesFeatureState,
  (items: any) => items.mammaMia
);
