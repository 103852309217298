import { Injectable, OnDestroy } from '@angular/core';
import { OverflowHandlerService } from './overflow-handler.service';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { globalModalParams } from '../shared/models/interfaces/global-modal-params.interface';

@Injectable({
  providedIn: 'root'
})
export class GlobalModalService<InputModel = any> implements OnDestroy {
  public component$ = new BehaviorSubject<any>(null);
  public isOpen = new Subject<boolean>();
  public params: globalModalParams | null = null;
  public inputs$ = new BehaviorSubject<InputModel>(null);

  public response$ = new ReplaySubject<any>(1);
  public closeOnOutsideClick = true;
  public disableInspect: boolean = false;
  public modalWrapperClass: string = '';

  private modalQueue: Array<{
    component: any;
    params: globalModalParams | null;
    inputs: InputModel | null;
    closeOnOutsideClick: boolean;
    disableInspect: boolean;
    modalWrapperClass: string;
  }> = [];
  private isModalOpen: boolean = false;
  private currentComponent: any = null;

  constructor(private overflowHandlerService: OverflowHandlerService) {}

  open(
    component: any,
    params = null,
    inputs: InputModel = null,
    closeOnOutsideClick: boolean = true,
    disableInspect: boolean = false,
    modalWrapperClass: string = ''
  ): void {
    // Check if the component is already open or queued
    const isAlreadyQueued = this.modalQueue.some(modal => modal.component === component);
    const isCurrentlyOpen = this.isModalOpen && this.currentComponent === component;

    if (isAlreadyQueued || isCurrentlyOpen) {
      // Component is already open or queued, do not queue it again
      return;
    }
    this.modalQueue.push({
      component,
      params,
      inputs,
      closeOnOutsideClick,
      disableInspect,
      modalWrapperClass
    });

    if (!this.isModalOpen) {
      this.openNextModal();
    }
  }

  private openNextModal(): void {
    if (this.modalQueue.length > 0) {
      const nextModal = this.modalQueue.shift();
      this.isModalOpen = true;
      this.currentComponent = nextModal.component;
      this.modalWrapperClass = nextModal.modalWrapperClass;
      this.overflowHandlerService.hide();
      this.component$.next(nextModal.component);
      this.isOpen.next(true);
      this.params = nextModal.params;
      this.inputs$.next(nextModal.inputs);
      this.closeOnOutsideClick = nextModal.closeOnOutsideClick;
      this.disableInspect = nextModal.disableInspect;
    } else {
      this.isModalOpen = false;
      this.currentComponent = null;
    }
  }

  close(response: any = null): void {
    this.overflowHandlerService.show();
    this.component$?.next(null);
    this.isOpen.next(false);
    this.inputs$.next(null);
    this.params = null;

    if (response !== null) {
      this.response$.next(response);
    }
    this.response$.complete();
    this.response$ = new ReplaySubject<any>(1);

    this.closeOnOutsideClick = true;
    this.disableInspect = false;
    this.modalWrapperClass = '';
    this.isModalOpen = false;
    this.currentComponent = null;

    setTimeout(() => {
      this.openNextModal();
    }, 0);
  }

  removeComponent(component: any): void {
    // Remove the component from the queue
    this.modalQueue = this.modalQueue.filter(modal => modal.component !== component);

    // If the component is currently open, close it
    if (this.isModalOpen && this.currentComponent === component) {
      this.close();
    }
  }

  hideCloseButton(): void {}

  ngOnDestroy(): void {
    this.component$?.unsubscribe();
    this.response$?.unsubscribe();
  }
}
