export const environment = {
  production: false,
  develop: true,
  staging: false,
  cmsURL: 'https://catalog.dev.extra.ge/',
  customersURL: 'https://customer.dev.extra.ge/',
  reportingURL: 'https://reporting.dev.extra.ge/',
  paymentURL: 'https://payment.dev.extra.ge/',
  orderingURL: 'https://ordering.dev.extra.ge/',
  basketUrl: 'https://basket.dev.extra.ge/',
  apiUrl: 'https://identity.dev.extra.ge/',
  emailUrl: 'https://notification.dev.extra.ge/',
  issuerUrl: 'https://identity.dev.extra.ge',
  marketingURL: 'https://marketing.dev.extra.ge/',
  tradeInUrl: 'https://tradein-api.dev.extra.ge/',
  cdnUrl: 'https://cdn.dev.extra.ge/',
  baseUrl: 'https://dev.extra.ge',
  juridicalUrl: 'https://juridicalidentity.dev.extra.ge/',
  juridicalIssuerUrl: 'https://juridicalidentity.dev.extra.ge',
  adultCallback: 'catalog/bavshvta-samqaro',
  mercuryURL: 'https://mercury.dev.extra.ge/',
  'wall-e': 'https://wall-e.dev.extra.ge/',
  deliveryCalculationApi: 'https://delivery-datecalculation-api.dev.extra.ge/',
  deliveryIntergrationURL: 'https://delivery-integration-api.dev.extra.ge/',
  mixpanelApiURL: 'https://mixpanel-api.dev.extra.ge/',
  dynamicYieldURL: 'https://direct.dy-api.eu/',
  dynamicYieldCollectURL: 'https://direct-collect.dy-api.eu/',
  dynamicYieldApiKey: 'e0f6b617006c4957e17717a2f8dd5e44c889859be2104609e1e8e32a3002b723',
  recaptchaSiteKey: '6Ld3F-EqAAAAAF3Z8uc0BEiD1hQdISRYBvjbCF33'
};
