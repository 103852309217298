import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OrderDebtService } from '../../../services/order-debt.service';
import { PaymentType, ReferrerType } from '../../../store/models/orders.model';
import { PayExpressOrderDebt } from '../../../store/actions/orders.actions';
import { select, Store } from '@ngrx/store';
import { filter, map, Observable, tap } from 'rxjs';
import { UserModel } from '../../../store/models/user.model';
import { getUserData } from '../../../store/reducers/user.reducer';
import { getCards } from '../../../store/actions/cards.actions';
import { BankTypeForCard, CardModel } from '../../../store/models/card.model';
import { cardsFeatureSelector } from '../../../store/reducers/cards.reducer';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-debt-payment-modal',
  templateUrl: './debt-payment-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true
})
export class DebtPaymentModalComponent implements OnInit {
  selectedPaymentMethod = PaymentType.IPay;
  orderDebt = this.orderDebtService.infoForPayment?.orderDebt;
  paymentType = PaymentType;
  selectedCard: CardModel;
  user$: Observable<UserModel> = this.store.pipe(select(getUserData));
  cards$: Observable<CardModel[]>;

  constructor(
    public orderDebtService: OrderDebtService,
    private store: Store<any>
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getCards());
    this.initCard();
  }

  initCard() {
    this.cards$ = this.store.select(cardsFeatureSelector).pipe(
      filter(cards => !!cards?.length),
      map(cards => cards.filter(card => card.bankType === BankTypeForCard.BOG)),
      tap(cards => (this.selectedCard = cards[0]))
    );
  }

  onBalance() {
    this.selectedCard = undefined;
    this.selectedPaymentMethod = this.paymentType.Balance;
  }

  expressOrderDebtPayment() {
    this.store.dispatch(
      PayExpressOrderDebt({
        id: this.orderDebtService.infoForPayment?.selectedOrderPaymentId || null,
        paymentType: this.selectedPaymentMethod,
        referrerType: ReferrerType.Web,
        cardId: this.selectedCard?.id || null
      })
    );
  }
}
