import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { ScriptsService } from './services/scripts.service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, of, startWith, Subscription, switchMap, takeUntil } from 'rxjs';
import { GlobalDataHttp, LegalEntityService, Unsubscriber } from 'lib-core';
import { GlobalModalService } from './services/global-modal-service';
import { Store } from '@ngrx/store';
import { getUserData } from './store/reducers/user.reducer';
import { PrivacyPolicyService } from './services/privacy-policy.service';
import { UserTypeService } from './services/user-type.service';
import { UserDataService } from './services/user-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends Unsubscriber {
  privacyPolicySubscription: Subscription;

  constructor(
    private meta: Meta,
    private scripstService: ScriptsService,
    private router: Router,
    private globalModalService: GlobalModalService,
    private store: Store,
    private privacyPolicyService: PrivacyPolicyService,
    private userTypeService: UserTypeService,
    private legalEntityService: LegalEntityService,
    private globalDataHttp: GlobalDataHttp,
    private userDataService: UserDataService
  ) {
    super();
    if (!environment.production) {
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    }
    this.scripstService.loadScript();
  }

  private get userData$(): Observable<any> {
    if (this.userTypeService.isUserLegalEntity) {
      const response = this.legalEntityService.getCustomer();
      this.userTypeService.setlegalEntityUser(response);
      return response;
    } else {
      return this.store.select(getUserData);
    }
  }

  ngOnInit(): void {
    this.isImpersonatedUser();
  }

  private isImpersonatedUser() {
    this.globalDataHttp.isImpersonatedUser().subscribe({
      next: res => {
        this.userDataService.setImpersonatedUser(res);
        if (res) {
          alert('ადმინკიდან გახსნილი მომხმარებლის გვერდი');
        }
      },
      error: err => {
        this.userDataService.setImpersonatedUser(false);
      }
    });
  }
}
