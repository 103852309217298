import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BasketHttp, CatalogHttp } from 'lib-core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, takeUntil, tap, switchMap } from 'rxjs/operators';
import { CommonService } from '../../services/common.service';
import { ComparisonService } from '../../services/comparison.service';
import { ToastService } from '../../services/toast.service';
import {
  checkComparisonProducts,
  clearComparison,
  clearComparisonSuccess,
  deleteComparison,
  deleteComparisonFail,
  deleteComparisonSuccess,
  getComparison,
  getComparisonFail,
  getComparisonSuccess,
  updateComparison,
  updateComparisonFail,
  updateComparisonSuccess
} from '../actions/comparison.actions';
import { WishlistModel } from '../models/wishlist.model';
import { getUserStatus } from '../reducers/auth.reducer';

@Injectable()
export class ComparisonEffects {
  userStatus$ = this.store.pipe(select(getUserStatus));
  isLoggedIn: boolean;
  isDelete: boolean;
  isClear: boolean;
  newProduct;
  isComparisonPage: boolean;
  private destroy$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private catalogHttp: CatalogHttp,
    private store: Store<WishlistModel>,
    private comparisonService: ComparisonService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private toastService: ToastService
  ) {
    this.userStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.isLoggedIn = status;
    });
  }

  updateComparisonProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(updateComparison),
      switchMap((res: any) => {
        this.newProduct = res;

        if (this.isLoggedIn) {
          return this.catalogHttp.addUserComparisonProductSingle(res.productId).pipe(
            tap(() => this.store.dispatch(getComparison())),
            map(() => {
              this.toastService.showToastEvent(null, 'პროდუქტი დაემატა შესადარებლად', 'success', true);
              return updateComparisonSuccess();
            }),
            catchError(error => {
              this.comparisonService.handleErrorCodes(error.errors.DomainValidationErrors[0], this.newProduct);
              return of(updateComparisonFail(error));
            })
          );
        }

        let productsData = this.comparisonService.getCorrectProductsData(res);

        return this.catalogHttp.getAnoymusUserComparisonProducts({ productIds: productsData.productIds }).pipe(
          tap(() => this.store.dispatch(getComparison())),
          map(() => {
            this.toastService.showToastEvent(null, 'პროდუქტი დაემატა შესადარებლად', 'success', true);
            return updateComparisonSuccess();
          }),
          catchError(error => {
            return of(updateComparisonFail(error));
          })
        );
      })
    )
  );

  getComparisonProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(getComparison),
      switchMap(() => {
        let productsData = this.comparisonService.getCorrectProductsData(this.newProduct ? this.newProduct : null); //add product to storage and get
        if (this.isLoggedIn) {
          this.comparisonService.clearComparisonLocalStorage();
          return this.catalogHttp.getComparisonProducts().pipe(
            map(res => getComparisonSuccess({ productIds: res })),
            catchError(error => {
              return of(getComparisonFail(error));
            })
          );
        }
        return this.catalogHttp.getAnoymusUserComparisonProducts({ productIds: productsData.productIds }).pipe(
          map(res => getComparisonSuccess({ productIds: res })),
          catchError(error => {
            return of(getComparisonFail(error));
          })
        );
      }),
      tap(() => {
        if (this.isDelete || this.isClear) {
          // when we delete product from comparison, we need to get products again
          this.comparisonService.listenComparisonDelete();
        }
      })
    )
  );
  comparisonSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getComparisonSuccess),
        tap(result => {
          let productsData = this.comparisonService.getCorrectProductsData(this.newProduct ? this.newProduct : null);
          if (!this.isLoggedIn) {
            this.comparisonService.transformAndSaveComparisonInStorage(productsData.comparisonArray, result.productIds);
          }

          if ((this.isDelete || this.isClear) && this.isComparisonPage) {
            // when we delete product from comparison, we need to get products again
            this.comparisonService.listenComparisonDelete();
          }

          this.newProduct = null;
        })
      ),
    { dispatch: false }
  );
  clearComparisonProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(clearComparison),
      switchMap(() => {
        this.isClear = true;
        this.comparisonService.clearComparisonLocalStorage();

        if (this.isLoggedIn) {
          return this.catalogHttp.clearComparison();
        }
        return of(null);
      }),
      tap(() => this.store.dispatch(getComparison())),
      map((res: any) => {
        return clearComparisonSuccess({ productIds: [] });
      })
    )
  );

  deleteComparisonProduct = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteComparison),
      switchMap(res => {
        this.isDelete = true;
        if (this.isLoggedIn) {
          return this.catalogHttp.deleteComparisonProduct(res.productId).pipe(
            catchError(error => {
              this.toastService.showToastEvent(null, 'გთხოვთ სცადოთ თავიდან', 'error', true);
              return deleteComparisonFail(error);
            })
          );
        }
        this.comparisonService.removeComparisonProductFromLocalStorage(res.productId);
        return of(null);
      }),
      tap(() => this.store.dispatch(getComparison())),
      map((products: any) => deleteComparisonSuccess(products)) //aren't using in reducers for now),
    )
  );
}
